import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CBadge, CNavItem } from '@coreui/react';

export const AppSidebarNav = ({ items }) => {
  const location = useLocation();

  const navLink = (name, icon, badge) => (
    <>
      {icon && icon}
      <div className="nav-text">{name}</div> {/* Wrap in a div */}
      {badge && (
        <CBadge color={badge.color} className="ms-auto">
          {badge.text}
        </CBadge>
      )}
    </>
  );

  const isActiveRoute = (path) => location.pathname.startsWith(path);


  const navItem = (item, index) => {
    const { name, badge, icon, to } = item;
    return (
      <CNavItem key={index}>
        <NavLink
          to={to}
          className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
        >
          {navLink(name, icon, badge)}
          {isActiveRoute(to) && (
            <div className="active-indicator"></div>
          )}
        </NavLink>
      </CNavItem>
    );
  };

  const navGroup = (item, index) => {
    const { name, icon, to, items } = item;
    const isParentActive = items.some((subItem) => isActiveRoute(subItem.to));

    return (
      <div key={index} className={`nav-group ${isParentActive ? 'active' : ''}`}>
        <span className="nav-group-header">
          {navLink(name, icon)}
          {isParentActive && (
            <div className="active-indicator"></div> /* Add indicator for active parent */
          )}
        </span>
        {items.map((subItem, subIndex) => navItem(subItem, subIndex))}
      </div>
    );
  };

  return (
    <>
      {items.map((item, index) =>
        item.items ? navGroup(item, index) : navItem(item, index)
      )}
    </>
  );
};

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
