import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { base_url } from "../../global/global";

const Reset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailFromQuery = queryParams.get("email");
  const [email, setEmail] = React.useState(emailFromQuery || "");
  const [errMsg, setErrMsg] = React.useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }
  };

  const handleSubmit = async (e) => {
    

    e.preventDefault();
    const data = {
      email: email,
      screenType: "other"
    };
    try {
      if (email === "") {
        setErrMsg("Email is required");
        setTimeout(() => {
          setErrMsg("");
        }, 3000);
        return false;
      }
      if (email) {
        const response = await axios.post(
          `${base_url}sendotp`,
          data
        );
        if (response.status === 200) {
          navigate("/reset");
        }
        if(response?.status===404){
       setErrMsg('Invalid User , As No User exist');
        }
      }

    } catch (error) {
      if (error?.status === 404 || error?.status === 403) {
        setErrMsg("Invalid User , As No User exist");
        setTimeout(() => {
          setErrMsg("");
        }, 3000);
        return false;
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 col-md-6 intro-section">
            <div className="brand-wrapper"></div>
          </div>
          <div className="col-sm-6 col-md-6 form-section">
            <div className="login-wrapper ">
              <div className="text-center pb-4">
                <h2 className="login-title ">Reset Your Password </h2>
                <p>
                  Please provide your email and we’ll send a link to reset your
                  password.
                </p>
                {errMsg ? (
                  <span
                    className="mt-4 ml-5 text-center rg-err"
                    style={{ fontSize: 14 }}
                  >
                    {errMsg}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <form action="#!">
                <div className="">
                  <label className="sr-only sr-onlys"> Email </label>

                  <input
                    type="email"
                    name="email"
                    value={email}
                    id="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={handleOnChange}
                  />
                </div>

                <div className="col-12">
                  <div className="d-grid">
                    <button
                      onClick={handleSubmit}
                      className="shadow-none rg-btn-dark mt-4"
                    >
                      Submit
                    </button>{" "}
                    <button
                      onClick={() => {
                        navigate('/');
                      }}
                      className=" rv-reset-cancel mt-4"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
