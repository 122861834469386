

export const base_url = `https://reviveapi.tactionclients.com/`;


//

export const getAuthHeader = (token) => {
  return {
      headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
      }
  };
};
