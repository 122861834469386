import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeBilling: null,
  billingSession: null,
  lastSessionTime: 0,
  sessionDate: null,
  loading: false,
  coachId: null,
  error: null,
  billingRecords: [],
  billingSummary: [],
  coachRecords: [],
  billingStatus: [],
  cptReports: []
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setTime: (state, action) => {
      state.time = action.payload;
    },

    startSession: (state) => {
      const currentDate = new Date().toISOString().split('T')[0];
      if (state.sessionDate === currentDate) {
        state.time = state.lastSessionTime;
      } else {
        state.time = 0;
        state.sessionDate = currentDate;
      }
      state.isRunning = true;
      state.loading = true;
      state.error = null;
    },

    pauseSession: (state) => {
      // Save the paused time as the last session time
      state.lastSessionTime = state.time;
      state.isRunning = false;
      state.loading = false;
    },

    resetSession: (state) => {
      state.time = 0;
      state.isRunning = false;
      state.loading = false;
      state.error = null;
      state.lastSessionTime = 0; // Reset last session time on reset
    },

    startLoading: (state) => {
      state.loading = true;
      state.error = null;
    },

    setActiveBilling: (state, action) => {
      state.activeBilling = action.payload;
      state.loading = false;
    },

    setBillingSession: (state, action) => {
      state.billingSession = action.payload;
      state.loading = false;
    },

    setBillingSummary: (state, action) => {
      state.billingSummary = action.payload || [];
    },

    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    resetBilling: (state) => {
      state.activeBilling = null;
      state.billingSession = null;
      state.error = null;
      state.loading = false;
    },

    setBillingAllRecords: (state, action) => {
      state.billingRecords = action.payload;
    },
    setBillingStatus: (state, action) => {
      state.billingStatus = action.payload;
    },
    setCptReports: (state, action) => {
      state.cptReports = action.payload;
    },

    setBillingCoachRecords: (state, action) => {
      state.coachRecords = action.payload;
    },
    setCoachId: (state, action) => {
      state.coachId = action.payload;
    },
  },
});

export const {
  startSession,
  pauseSession,
  setTime,
  resetSession,
  startLoading,
  setActiveBilling,
  setBillingSession,
  setError,
  resetBilling,
  setBillingAllRecords,
  setCoachId,
  setBillingSummary,
  setBillingStatus,
  setCptReports,
  setBillingCoachRecords
} = billingSlice.actions;

export default billingSlice.reducer;
