import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: null,
  userType: null,
  token: null,
  id: null,
  userData: null,
  user_id: null,
  profileData: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { email, userType, token, id, userData, user_id } = action.payload;
      state.email = email;
      state.userType = userType;
      state.token = token;
      state.id = id;
      state.user_id = user_id;
      state.userData = userData;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },   
    logout: () => {
      return initialState;
    },
  },
});

export const { setUserData, logout, setProfileData } = userSlice.actions;
export default userSlice.reducer;
