import routeConfig from "./routes";
import useGlobalStore from "./global/ZustandStore";

const useSelectedRole = () => {
  const { adminRoutes, providerRoutes ,coachRoutes} = routeConfig;
//   const userType = useGlobalStore((state) => state.userType);
const userType = localStorage.getItem('userType');
console.log("cont" ,userType )

  // Choose routes based on userType
  const selectedRoutes = userType === "admin" ? adminRoutes : userType === "coach" ? coachRoutes  : providerRoutes;

  return selectedRoutes;
};

export default useSelectedRole;
