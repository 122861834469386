import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from '../../global/global';
import { setMyCoachingNotes } from '../reducers/coachReducer';

export const roleSlice = createApi({
  reducerPath: 'role',
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateRole: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/updateroleuser/${id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getMyCoachingNotes: builder.query({
      query: ({ patientId, page, limit }) => {
        const params = new URLSearchParams();

        if (page) params.append('page', page);
        if (limit) params.append('limit', limit);
        
        // Construct the URL for fetching patient details
        return `/coach/mycoachingnotes/${patientId}?${params.toString()}`;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setMyCoachingNotes(data));
          }
        } catch (error) {
          console.error("Error fetching coach data:", error);
        }
      },
    }),
  }),
});

export const { useUpdateRoleMutation, useGetMyCoachingNotesQuery } = roleSlice;
