import { format } from "date-fns";

export const calculatePercentageChange = (current, previous) => {
  if (previous === 0) {
    return null;
  }
  const percentageChange = ((current - previous) / previous) * 100;
  return percentageChange.toFixed(0);
};

export const dataURLtoBlob = (dataURL) => {
  const mime = dataURL?.match(/data:(.*?);base64,/)[1]; // Extract MIME type
  const byteString = atob(dataURL?.split(',')[1]);
  const arrayBuffer = new Uint8Array(byteString?.length);

  for (let i = 0; i < byteString.length; i++) {
    arrayBuffer[i] = byteString?.charCodeAt(i);
  }
  return new Blob([arrayBuffer], { type: mime });
};

export const getFileExtension = (mimeType) => {
  return mimeType.split('/')[1];
};

export const extractGraphData = (data, monthStartDate) => {
  if (!data || !monthStartDate) return [];

  const targetMonth = monthStartDate.getMonth();
  const targetYear = monthStartDate.getFullYear();

  const moodScores = data?.mood_score || [];
  const painScores = data?.pain_score || [];
  const sleepScores = data?.sleep_score || [];

  const daysInMonth = new Date(targetYear, targetMonth + 1, 0).getDate();

  const scoreMap = {};

  // Initialize scoreMap with default values
  for (let i = 1; i <= daysInMonth; i++) {
    scoreMap[i] = { name: String(i), Pain: 0, Mood: 0, Sleep: 0 };
  }

  // Function to process scores and keep only the latest value per day
  const processScores = (scores, type) => {
    const latestEntries = {};

    scores?.forEach(({ date, value }) => {
      const itemDate = new Date(date);
      if (itemDate.getMonth() === targetMonth && itemDate.getFullYear() === targetYear) {
        const day = itemDate.getDate();

        // Check if this is the latest entry for the day
        if (!latestEntries[day] || itemDate > new Date(latestEntries[day]?.date)) {
          latestEntries[day] = { date, value };
        }
      }
    });

    // Assign the latest values to scoreMap
    Object.entries(latestEntries).forEach(([day, { value }]) => {
      scoreMap[day][type] = value;
    });
  };

  processScores(moodScores, "Mood");
  processScores(painScores, "Pain");
  processScores(sleepScores, "Sleep");

  return Object.values(scoreMap);
};

export const extractWeeklyGraphData = (data, weekStartDate) => {
  if (!data || !weekStartDate) return [];

  const moodScores = data?.mood_score || [];
  const painScores = data?.pain_score || [];
  const sleepScores = data?.sleep_score || [];

  // Convert weekStartDate to Date object and calculate week range
  const startDate = new Date(weekStartDate);
  const weekMap = {};

  // Initialize weekMap with default values for each day
  for (let i = 0; i < 8; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    const formattedDate = currentDate.toISOString().split("T")[0];

    weekMap[formattedDate] = { name: currentDate.getDate().toString(), Pain: 0, Mood: 0, Sleep: 0 };
  }

  // Function to map only the latest scores for each date
  const mapLatestScores = (scores, key) => {
    const latestEntries = {}; // Store latest data for each date

    scores?.forEach(({ date, value }) => {
      const formattedDate = new Date(date).toISOString().split("T")[0];

      // Only update if it's a newer entry for the same date
      if (weekMap[formattedDate]) {
        if (!latestEntries[formattedDate] || new Date(date) > new Date(latestEntries[formattedDate]?.date)) {
          latestEntries[formattedDate] = { date, value };
        }
      }
    });

    // Assign latest values to weekMap
    Object.entries(latestEntries).forEach(([formattedDate, { value }]) => {
      weekMap[formattedDate][key] = value;
    });
  };

  // Process each category
  mapLatestScores(moodScores, "Mood");
  mapLatestScores(painScores, "Pain");
  mapLatestScores(sleepScores, "Sleep");

  // Return sorted data for the graph
  return Object.values(weekMap);
};

const RADIAN = Math.PI / 180;
export const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const textAnchor = x > cx ? "start" : "end";
  const fontSize = percent > 0.2 ? 14 : 12;
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={textAnchor}
      dominantBaseline="central"
      fontSize={fontSize}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const getDaysInMonthPatientReport = (year, month, rawData, currentMonthDatePatient) => {
  const days = [];
  
  // Get the total number of days in the given month
  const totalDays = new Date(year, month + 1, 0).getDate();

  const filterYear = currentMonthDatePatient.getFullYear();
  const filterMonth = currentMonthDatePatient.getMonth();

  const filteredData = rawData.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate.getFullYear() === filterYear && itemDate.getMonth() === filterMonth;
  });

  for (let day = 1; day <= totalDays; day++) {
    const dayString = day.toString();

    const matchingData = filteredData.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate.getDate().toString() === dayString;
    });

    // Get the latest entry for the given day
    let latestValue = 0;
    if (matchingData.length > 0) {
      const latestEntry = matchingData.reduce((latest, item) => {
        return new Date(item.date) > new Date(latest.date) ? item : latest;
      });
      latestValue = latestEntry.value;
    }

    days.push({
      name: dayString,
      value: latestValue, // Only take the latest value
    });
  }

  return days;
};

export const getCurrentWeekPatientReport = (year, rawData, currentWeekStartPatientReport) => {
  const referenceDate = new Date(currentWeekStartPatientReport);

  const currentWeekStart = new Date(referenceDate);
  currentWeekStart.setDate(referenceDate.getDate() - referenceDate.getDay() + 1);
  currentWeekStart.setHours(0, 0, 0, 0);

  const currentWeekEnd = new Date(currentWeekStart);
  currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
  currentWeekEnd.setHours(23, 59, 59, 999);

  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const weekData = [];

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(currentWeekStart);
    currentDate.setDate(currentWeekStart.getDate() + i);

    const matchingData = rawData?.filter((item) => {
      const itemDate = new Date(item?.date);
      return (
        itemDate >= currentWeekStart &&
        itemDate <= currentWeekEnd &&
        itemDate.getDate() === currentDate.getDate() &&
        itemDate.getFullYear() === year
      );
    });

    // Get the latest value based on the date
    const latestValue =
      matchingData?.length > 0
        ? matchingData?.reduce((latest, item) =>
            new Date(item?.date) > new Date(latest?.date) ? item : latest
          ).value
        : 0;

    weekData.push({
      name: weekDays[i],
      value: latestValue,
    });
  }

  const extraDate = new Date(currentWeekEnd);
  extraDate.setDate(currentWeekEnd.getDate() + 1);

  const extraData = rawData?.filter((item) => {
    const itemDate = new Date(item?.date);
    return (
      itemDate.getDate() === extraDate.getDate() &&
      itemDate.getFullYear() === year
    );
  });

  const extraValue =
    extraData.length > 0
      ? extraData.reduce((latest, item) =>
          new Date(item.date) > new Date(latest.date) ? item : latest
        ).value
      : 0;

  weekData.push({
    name: "",
    value: extraValue,
  });

  return weekData;
};

const formatDates = (date) => {
  const d = new Date(date);
  return d.toISOString().split("T")[0];
};
export  const calculatePercentagePerSelectedDate = (tasks, selectedDate) => {
  let totalDates = 0;
  let completedDates = 0;

  tasks?.forEach(task => {
    if (task?.date_range?.dates) {
      const filteredDates = task?.date_range?.dates?.filter((date) =>
        (date?.date) === format(new Date(selectedDate), "MM-dd-yyyy")
      );
      totalDates += filteredDates?.length;
      completedDates += filteredDates?.filter(date => date?.value === true).length;
    }
  });

  return totalDates === 0 ? 0 : ((completedDates / totalDates) * 100).toFixed(0);
};

export const calculateOverallCompletionPercentage = (tasks) => {
  let totalDates = 0;
  let completedDates = 0;

  tasks?.forEach(task => {
    if (task?.date_range?.dates) {
      totalDates += task?.date_range?.dates?.length;
      completedDates += task?.date_range?.dates?.filter((date) => date?.value === true).length;
    }
  });

  return totalDates === 0 ? 0 : ((completedDates / totalDates) * 100).toFixed(0);
};

const getWeekRange = (selectedDate) => {
  const date = new Date(selectedDate);
  const day = date.getDay(); 

  // Adjust the date to the Monday of the current week
  const startDate = new Date(date);
  startDate.setDate(date.getDate() - (day === 0 ? 6 : day - 1)); 

  // Adjust the date to the Sunday of the current week
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  return {
    start: formatDates(startDate),
    end: formatDates(endDate),
  };
};
export const calculateWeeklyCompletionPercentage = (tasks, selectedWeek) => {
  let totalDates = 0;
  let completedDates = 0;
  const { start, end } = getWeekRange(selectedWeek);

  tasks?.forEach((task) => {
    if (task?.date_range?.dates) {
      const filteredDates = task?.date_range?.dates?.filter((date) => (date?.date) >= format(new Date(start), "MM-dd-yyyy") && (date?.date) <= format(new Date(end), "MM-dd-yyyy"));
      totalDates += filteredDates?.length;
      completedDates += filteredDates?.filter((date) => date?.value === true).length;
    }
  });

  return totalDates === 0 ? 0 : ((completedDates / totalDates) * 100).toFixed(0);
};
