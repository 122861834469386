import routeConfig from "./routes";
import useGlobalStore from "./global";

const useSelectedRole = () => {
  const { adminRoutes, providerRoutes } = routeConfig;
//   const userType = useGlobalStore((state) => state.userType);
const userType = localStorage.getItem('userType')

  // Choose routes based on userType
  const selectedRoutes = userType === "Admin" ? adminRoutes : providerRoutes;

  return selectedRoutes;
};

export default useSelectedRole;
