// global.js
import { create } from 'zustand';

const useGlobalStore = create((set) => ({
  userType: '',

  setUserType: (type) => set({ userType: type }),
}));

export default useGlobalStore;
