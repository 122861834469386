////////////////////////////routes/////////////////////////////////////////
import React from "react";

const Clinics = React.lazy(() => import("./views/pages/admin/clinic/Clinic"));
const ClinicDetail = React.lazy(() => import("./views/pages/admin/clinic/ClinicDetail"));
const Patients = React.lazy(() => import("./views/pages/admin/patients/PatientList"));
const UpdatedBilledStatuses = React.lazy(() => import("./views/pages/admin/clinic/UpdatedBilledStatus"));
const Users = React.lazy(() => import("./views/pages/admin/users/UserList"));
const UserDetail = React.lazy(() => import("./views/pages/admin/users/UserDetail"));
const ReportNanalytics = React.lazy(() =>  import("./views/pages/admin/reportAnalytics/ReportNanalytics"));
const MyPatients = React.lazy(() =>  import("./views/pages/provider/MyPatients"));
const MyPatientsDetails = React.lazy(() =>  import("./views/pages/provider/MyPatientDetails"));
const Messages = React.lazy(() =>  import("./views/pages/provider/Messages"));
const Coachpatients = React.lazy(() =>  import("./views/pages/coach/coachPatients/CoachPatient"));
const CoachPatientDetail = React.lazy(() =>  import("./views/pages/coach/coachPatients/CoachPatientDetail"));
const CallLog = React.lazy(() =>  import("./views/pages/coach/callLogs/CallLog"));
const PolicyReference = React.lazy(() =>  import("./views/pages/coach/policyReference/PolicyReference"));
const LogParent=React.lazy(()=>import("../src/views/pages/coach/Logparent"));
const RTMDashboardPage=React.lazy(()=>import('./views/pages/coach/RTMdashboard/RtmDashboard'));
const Task = React.lazy(() =>  import("./views/pages/admin/clinic/Task"));


// Admin Routes
const adminRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/clinics", name: "List of Clinic", element: Clinics },
  { path: "/clinics/clinicDetail", name: "Clinic Detail", element: ClinicDetail  },
  { path: "/patients", name: "Patients", element: Patients },
  { path: "/users", name: "User", element: Users },
  { path: "/users/userDetail", name: "User Detail", element: UserDetail },
  { path: "/contentManagement",    name: "ContentManagement",    element: PolicyReference  },
  { path: "/report", name: "Report & analytics", element: ReportNanalytics },
  { path: "/task", name: "Task templete", element: Task },
];

// Provider Routes
const providerRoutes = [
  { path: "/mypatients", name: "My Patients", element: MyPatients },
  { path: "/mypatients/details", name: "Patient Detail", element: MyPatientsDetails },
  { path: "/messages", name: "Message Coach", element: Messages },
  
];

const coachRoutes = [
  { path: "/coachPatients", name: "Coach Patients", element: Coachpatients },
  { path: "/coachPatientDetail", name: "Coach Patient Detail", element: CoachPatientDetail },
  { path: "/callLog", name: "Call Log", element: CallLog },
  { path: "/policyReference", name: "Policy Reference", element: PolicyReference },
  { path:'/rtmDashboard',name:"RTM Dashboard",element:RTMDashboardPage},
  { path: "/log",name:"log",element:LogParent},  
  { path: "/task", name: "Task templete", element: Task },
];

const clinicAdminRoutes = [
  { path: "/patients", name: "Patients", element: Patients },
  { path: "/updateBilledStatus", name: "Update Billed Status", element: UpdatedBilledStatuses },
];

export default {adminRoutes, providerRoutes , coachRoutes, clinicAdminRoutes};
