import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  patients: [],
  patient: [],
  clinic_id: [],
  patientsGraph: [],
};

const allPatientSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setPatients: (state, action) => {
      state.patients = action.payload;
    },
    setPatientsById:(state, action)=>{
      state.patient = action.payload;
    },
    setClinicId:(state, action)=>{
      state.clinic_id = action.payload;
    },
    setAllPatientsGraph: (state, action)=>{
      state.patientsGraph = action.payload;
    }
  },
});

export const { setPatients, setPatientsById, setClinicId, setAllPatientsGraph} = allPatientSlice.actions;

export default allPatientSlice.reducer;
