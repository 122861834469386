import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultLayout from "./Components/Layout/Defaultlayout";
import Reset from "./Components/Login/Reset";
import ResetMessage from "./Components/Login/ResetSend";
import ChangePassword from "./Components/Login/ChangePassword";
import Changed from "./Components/Login/Changed";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./ProtectedRoute";
const Login = React.lazy(() => import("./Components/Login/Login"));

const LoadingSpinner = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        toastClassName="custom-toast"
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/reset" element={<ResetMessage />} />
          <Route path="/change-password/:id?" element={<ChangePassword />} />
          <Route path="/changed" element={<Changed />} />
          <Route path="*" element={<ProtectedRoute element={<DefaultLayout />} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
