import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { setClinicsListing } from "../reducers/clinicReducer";
import { setClinicRecord } from "../reducers/clinicReducer";
import { toast } from "react-toastify";

export const clinicSlice = createApi({
  reducerPath: "clinicApi",
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.user?.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Clinics","Clinic","updateClinic"],
  endpoints: (builder) => ({
    addClinic: builder.mutation({
      query: (clinicData) => ({
        url: "/addclinic",
        method: "POST",
        body: clinicData,
      }),
      // async onQueryStarted(arg, { queryFulfilled }) {
        
      //   try {
      //     const { data } = await queryFulfilled;
      //     if(data){
      //       toast.success("Clinic Added Successfully");
      //       }
      //     } catch (err) {
      //       toast.error(err?.error?.data?.message);
      //     }
      //   }, 
      invalidatesTags: ["Clinics"],
    }),
    getClinic: builder.query({
      query: ({clinicData,filter, page, rowsPerPage}) => ({
      url: `/getcliniclist?searchtext=${filter?.filterText?filter?.filterText:''}&datefrom=${filter?.startDate?filter?.startDate:""}&dateto=${filter?.endDate?filter?.endDate:""}&status=${filter?.status?filter?.status:""}&page=${page}&limit=${rowsPerPage}`,
        method: "GET",
        body: clinicData,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setClinicsListing(data));
        } catch (err) {
          console.error("Query Error:", err);
        }
      },
      providesTags: ["Clinics"],
    }),

    getClinicRecord:builder.query({
      query:({id,filter, page, rowsPerPage})=>`/clinicrecord/${id}?searchtext=${filter?.filterText?filter?.filterText:''}&datefrom=${filter?.startDate?filter?.startDate:""}&dateto=${filter?.endDate?filter?.endDate:""}&status=${filter?.status?filter?.status:""}&page=${page}&limit=${rowsPerPage}`,
    
     async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        dispatch(setClinicRecord(data));
      } catch (err) {
        console.error("Query Error:", err);
      }
    },
    providesTags: ["Clinic"]
    }),
    addClinicUser: builder.mutation({
      query: (userData) => ({
        url: '/addrolesuser',
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ["Clinic"]
    }),
    deleteClinicUser: builder.mutation({
      query: ({id, reassigId}) => ({
        url: `/deleteuserrole/${id}`,
        method: 'PATCH',
        body: reassigId
      }),
      invalidatesTags: ["Clinic"]
    }),
    updateClinicUser:builder.mutation({
      query:({userId,updateData})=>({
        url:`/updateroleuser/${userId}`,
        method:'PUT',
        body:updateData
      }),
      invalidatesTags:["Clinic"]
    }), 
    updateClinic:builder.mutation({
      query:({id, payload})=>({
        url:`updateclinic/${id}`,
        method:'PUT',
        body: payload
      }),
      invalidatesTags:["Clinic"]
    }), 

    updateClinicPatient: builder.mutation({
      query: (data,id) => ({
        url: `/updateclinic/${id}`,
        method: 'PUT',
        body :data
      }),
      async onQueryStart(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("Clinic Updated Successfully");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        }, 
      invalidatesTags: ["Clinic"]
    }),

  }),
});

export const { useAddClinicMutation, useUpdateClinicMutation, useGetClinicQuery,useGetClinicRecordQuery,useAddClinicUserMutation,useUpdateClinicUserMutation,useDeleteClinicUserMutation,useUpdateClinicPatientMutation } = clinicSlice;

