import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react'

import { AppSidebarNav } from './AppSidebarNav'


import SimpleBar from 'simplebar-react'

import 'simplebar-react/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import RVLogo from '../assets/brand/logo/Revivie.png'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
         <CSidebarBrand className="d-none d-md-flex" to="/">
         <span className=" sidebar-header ">
                {" "}
                <img src={RVLogo} alt="Logo" width="140" height="29" />
              </span>
      </CSidebarBrand>
     
      <CSidebarNav>
        <SimpleBar className='my-3'>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
     
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
