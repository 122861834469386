import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from '../../global/global';
import { setRtmGuidelines } from '../reducers/patientUseFullResourcesSlice';
import { toast } from 'react-toastify';

export const RTMguidelinesSlice = createApi({
  reducerPath: 'RTMguidelines',
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user?.token;
      
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  tagTypes: ['RTMGuidelines'],
  endpoints: (builder) => ({
    getAllRTMGuidelines: builder.query({
      query: ({page, rowsPerPage}) => `/admin/rtmguidelineslist?${page}&limit=${rowsPerPage}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data){
            dispatch(setRtmGuidelines(data));
          }
        } catch (err) {
          console.error('Query Error:', err);
        }
      },
      providesTags: ['RTMGuidelines'],
    }),
    postRtmGuideLines: builder.mutation({
      query: (data) => ({
          url: '/admin/addrtmguidelines',
          method: 'POST',
          body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("RTM guideline added successfully!");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
      },
      invalidatesTags: ['RTMGuidelines']
    }),
    updateGuideLines: builder.mutation({
      query: ({payload, id}) => ({
        url: `/admin/editrtmguidelines/${id}`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("RTM guideline updated successfully!");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
      },
      invalidatesTags: ['RTMGuidelines']
    }),
    deleteRTMGuideLines: builder.mutation({
      query: (id) => ({
        url: `/admin/deletertmguidelines/${id}`,
        method: 'PATCH',
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("RTM guideline deleted successfully!");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
      },
      invalidatesTags: ['RTMGuidelines']
    })
  }),
});

export const { useGetAllRTMGuidelinesQuery, usePostRtmGuideLinesMutation, useUpdateGuideLinesMutation, useDeleteRTMGuideLinesMutation } = RTMguidelinesSlice;
