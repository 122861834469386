import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import avatar8 from "../assets/avatars/3.png";
import bellLogo from "../assets/icons/bell_Icon.png";
import ReviveLogo from  '../assets/brand/logo/GreenRevive.png'

// import InputGroupText from "react-bootstrap/esm/InputGroupText";
import {
  CAvatar,
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CNavItem,
  CNavLink,
} from "@coreui/react";
import useGlobalStore from "../global/ZustandStore";
import AppHeaderDropdown from "./header/AppHeaderDropdown";


// import routes from "../routes";

const AppHeader = ({userType}) => {
  const dispatch = useDispatch();
  const [searchList, setSearchList] = useState([]);
  const [selectedSearch, setSelectedSearch] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const navigate = useNavigate();
  const location = useLocation();
  // const userType = useGlobalStore((state) => state.userType);


  console.log("AppHeader-user" , userType )
  //   const routeName = routes.routes.find(route => route.path === location.pathname)?.name;
 


  //   const headerOption = () => {
  //     try {
  //       axios
  //         .get(`https://rgroupapi.tactionclients.com/api/v1/devicemodellist`)
  //         .then((res) => {
  //           const result = res.data.data.device_model_details;
  //           const result1 = res.data.data;
  //           const final_result = result.map((item) => {
  //             const data1 = item.device_model_type;
  //             const data2 = item.device_model;
  //             const data3 = item.device_model_version;

  //             const data = [data1, data2, data3];

  //             // console.log("Concatenated Data:", data);
  //             setDataArray(result1);

  //             return data;
  //           });

  //           const flattenedArray = final_result.flat();

  //           const uniqueArray = [...new Set(flattenedArray)];

  //           setSearchList(uniqueArray);
  //         });
  //     } catch (error) {
  //       alert(" No Data Found");
  //     }
  //   };

  const filteredList = searchList.filter((item) =>
    item.toLowerCase().includes(selectedSearch)
  );

  const handleInputClick = () => {
    setShowDropDown((prevShowDropDown) => !prevShowDropDown);
  };

  const handleInputChange = (e) => {
    setSelectedSearch(e.target.value);
  };

  const handleListItemClick = (item) => {
    setSelectedSearch(item.device_model_type);
    setShowDropDown(false);
    localStorage.setItem("search", item);
    localStorage.setItem("dataArray", JSON.stringify(dataArray));

    navigate(`/searchdevice`, {
      state: { dataArray: dataArray },
    });
    setSelectedSearch("");
  };

  return (
    <div>
      <CHeader position="sticky" className="">
        <CContainer fluid>
          <div id="myDropdown" className="dropdown-content">
            {/* <h2>{routeName}</h2> */}
          </div>
          <CHeaderBrand className="mx-auto d-md-none" to="/">
            {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
          </CHeaderBrand>
          <CHeaderNav className="d-md-flex me-auto">
            {userType === "Provider" ?  <img src={ReviveLogo} alt="Revive Logo"/> :"" }
         
          </CHeaderNav>
          &nbsp;&nbsp;
          {/* ///////////////////////bell icon ////////////////////////////////////////// */}
          <CHeaderNav>
            <CNavItem>
              <CNavLink style={{ cursor: "pointer", display: "flex" }}>
                <div className="headerbell" style={{ marginRight: "20px" }}>
                  {/* <img src={Help} height="30" /> */}
                </div>
                <div className="headerbell px-4">
                  <img src={bellLogo} height="25" />
                </div>
              </CNavLink>
            </CNavItem>
          </CHeaderNav>
          <CAvatar src={avatar8} size="md" />
          <div className="rv-info">
            <div className="col-md-12 col-sm-12">
              <strong className="rv-drp">Jon Deo</strong>
            </div>

            <div className="w-100 d-none d-md-block fs-5"></div>

            <div className="col-12 col-sm-12 rv-drp-us ">{userType}</div>
          </div>
          <CHeaderNav className="ms-3">
            <AppHeaderDropdown />
          </CHeaderNav>
        </CContainer>
        {/* <CHeaderDivider /> */}
        {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
      </CHeader>
      {/* {selectedSearch && <SearchDevice selectedSearch = {selectedSearch}/> } */}
    </div>
  );
};

export default AppHeader;
