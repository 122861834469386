  import axios from 'axios';
  export const ADD_DEPARTMENT_REQUEST = 'ADD_DEPARTMENT_REQUEST';
  export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
  export const ADD_DEPARTMENT_FAILURE = 'ADD_DEPARTMENT_FAILURE';

  export const addDepartment = (departmentData) => {
    return (dispatch) => {
      dispatch({ type: ADD_DEPARTMENT_REQUEST });


    
      

      // Perform the API request here
      const add_department_url = 'https://rgroupapi.tactionclients.com/api/v1/createdepartment';
     axios.post(add_department_url, departmentData)
        .then((response) => {
          dispatch({ 
            type: ADD_DEPARTMENT_SUCCESS,
            payload: departmentData
          });
          console.log("data added sucessfully")
        })
        .catch((error) => {
          dispatch({
            type: ADD_DEPARTMENT_FAILURE,
            payload: error.response.data
          });
        });
    };
  };




