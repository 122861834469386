import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { toast } from "react-toastify";

export const billingApi = createApi({
    reducerPath: "billingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: base_url,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().user?.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Billing"],
    endpoints: (builder) => ({
        // 🔹 Get Active Billing for a Patient
        getActiveBilling: builder.query({
            query: (patient_id) => `/billing/active/${patient_id}`,
            providesTags: ["Billing"],
        }),

        getAllBillingRecords: builder.query({
            query: (patient_id) => `/billing/all-billing/${patient_id}`,
            providesTags: ["Billing"],
        }),
        getAllBillingRecordsFromCoach: builder.query({
            query: (coach_id) => `/billing/all-billing-by-coach/${coach_id}`,
            providesTags: ["Billing"],
        }),
        // 🔹 Create a Billing Record
        createBilling: builder.mutation({
            query: (data) => ({
                url: "/billing/create-billing",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    console.log("Billing record created successfully!");
                } catch (err) {
                    console.error(err?.error?.data?.message || "Failed to create billing.");
                }
            },
            invalidatesTags: ["Billing"],
        }),

        // 🔹 Start Session
        startSession: builder.mutation({
            query: (data) => ({
                url: "/billing/start-session",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Session started successfully!");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to start session.");
                }
            },
            invalidatesTags: ["Billing"],
        }),

        // 🔹 Pause Session
        pauseSession: builder.mutation({
            query: (data) => ({
                url: "/billing/pause-session",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Session paused successfully!");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to pause session.");
                }
            },
            invalidatesTags: ["Billing"],
        }),

        // 🔹 Update Billing to "Ready_to_bill"
        readyToBill: builder.mutation({
            query: (data) => ({
                url: "/billing/pending-status",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Billing marked as Ready to Bill.");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to update billing status.");
                }
            },
            invalidatesTags: ["Billing"],
        }),

        // 🔹 Get Billing Session (Admin)
        getBillingSession: builder.query({
            query: () => "/billing/get-billing-session",
            providesTags: ["Billing"],
        }),

        // 🔹 Admin: Cancel Billing
        cancelBilling: builder.mutation({
            query: (data) => ({
                url: "/billing/cancel-billing",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Billing session cancelled.");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to cancel session.");
                }
            },
            invalidatesTags: ["Billing"],
        }),
    }),
});

export const {
    useGetActiveBillingQuery,
    useGetAllBillingRecordsQuery,
    useGetAllBillingRecordsFromCoachQuery,
    useCreateBillingMutation,
    useStartSessionMutation,
    usePauseSessionMutation,
    useReadyToBillMutation,
    useGetBillingSessionQuery,
    useCancelBillingMutation,
} = billingApi;
