import { createApi,fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { toast } from "react-toastify";
import { setProfileData } from "../reducers/userReducer";

export const loginSlice=createApi({
    reducerPath:'login',
    tagTypes:['login'],
    baseQuery:fetchBaseQuery({
        baseUrl: base_url,
        prepareHeaders: (headers) => {
          const token = localStorage.getItem('access_token');
          
          // If the token exists, set it in the Authorization header
          if (token) {
            headers.set('Authorization', `Bearer ${token}`);
          }
          return headers;
        },
    }),

   endpoints:(builder)=>({
    login:builder.mutation({
      providesTags:['login'],
        query:(payload)=>({
        url:'login',
        method:'POST',
        body:payload 
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
        
          try {
            const { data } = await queryFulfilled;
            if(data){
              toast.success("Login Successfully");
              }
            } catch (err) {
              toast.error(` ${err?.error?.data?.message}`);
            }
          },  
    }),
    updateRole: builder.mutation({
      invalidatesTags: ["login"],
      query: ({ id, payload}) => ({
        url: `/updateroleuser/${id}`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("Profile Updated successfully");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        }, 
    }),
    getProfileDetailsById: builder.query({
      query: (id) => `/getprofile/${id}`, 
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data){
            dispatch(setProfileData(data?.data?.user));
          }
        } catch (error) {
          console.error("Error fetching coach data:", error);
        }
      },
    }),
   })
});

 export const {useLoginMutation, useUpdateRoleMutation, useGetProfileDetailsByIdQuery}=loginSlice;