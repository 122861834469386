import {
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alex from "../../assets/icons/alex.png"; 
import ProfileModal from "../Modal/ProfileModal";

const AppHeaderDropdown = () => {
  const navigate = useNavigate();
  const [profileModal, setProfileModal] = useState(false);
  const [avatarURL, setAvatarURL] = React.useState(Alex);
  const [checked, setChecked] = useState({
    alertChecked: false,
    notificationChecked: false,
  });

  const closeModal = () => {
    setProfileModal(false);
  };

  const logoutHandler = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <FontAwesomeIcon icon={faAngleDown} />
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem
            onClick={() => {
              setProfileModal(true);
            }}
            className="title-color mt-2"
          >
            Profile
          </CDropdownItem>
          <CDropdownDivider />
          <CDropdownItem className="title-color" onClick={logoutHandler}>
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>

      <ProfileModal
        checked={checked}
        onCloseModal={closeModal}
        profileModal={profileModal}
        setAvatarURL={setAvatarURL}
        avatarURL={avatarURL}
        setChecked={setChecked}
      />
    </>
  );
};

export default AppHeaderDropdown;
