export const calculatePercentageChange = (current, previous) => {
    if (previous === 0) {
      return null;
    }
    const percentageChange = ((current - previous) / previous) * 100;
    return percentageChange.toFixed(0); 
};

export const dataURLtoBlob = (dataURL) => {
  const mime = dataURL?.match(/data:(.*?);base64,/)[1]; // Extract MIME type
  const byteString = atob(dataURL?.split(',')[1]);
  const arrayBuffer = new Uint8Array(byteString?.length);

  for (let i = 0; i < byteString.length; i++) {
    arrayBuffer[i] = byteString?.charCodeAt(i);
  }
  return new Blob([arrayBuffer], { type: mime });
};

export const getFileExtension = (mimeType) => {
  return mimeType.split('/')[1]; 
};