import React from "react";
import { useNavigate } from "react-router-dom";
import Success from "../../assets/icons/successImg.png";
import useGlobalStore from "../../global/ZustandStore";


const Changed = () => {
  const userType = useGlobalStore((state) => state.userType);
  const route_user = userType.toLowerCase();

  console.log("Typesss ", userType);
  const navigate = useNavigate();

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 col-md-6 intro-section">
            <div className="brand-wrapper"></div>
          </div>
          <div className="col-sm-6 col-md-6 form-section">
            <div className="login-wrapper ">
            

              <div className="text-center mt-3 pt-5 pb-2">
                <img src={Success} />

                <p className="para-reset 2 py-3 ">
                Your password has been changed successfully.
                </p>
              </div>
              <div className="col-12 pt-1">
                  <div className="d-grid">
                    <button
                     onClick={() => {
                      // navigate(`/${route_user}/login`);
                      navigate(`/admin/login`);
                    }}
                      className="shadow-none rg-btn-dark mt-4"
                    
                    >
                      login
                    </button>
                  </div>
                </div>

            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changed;
