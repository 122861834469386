import { createSlice } from "@reduxjs/toolkit";
const initialState={
    clinics:[],
    clinicListing: [],
    ClinicRecord:[]
};
const addClinics= createSlice({
    name:'clinics',
    initialState,
    reducers:
    {
        setClinicsListing:(state,action)=>{
            state.clinicListing=action.payload;
        },
        setClinicRecord:(state,action)=>{
        state.ClinicRecord=action.payload;
        },
    }
});

  export const {setClinicsListing,setClinicRecord}=addClinics.actions;

  export default addClinics.reducer;