import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Clinic",
    to: "/clinics",
  },
  {
    component: CNavItem,
    name: "Patient",
    to: "/patients",
  },
  {
    component: CNavItem,
    name: "User",
    to: "/users",
  },
  {
    component: CNavItem,
    name: "Content Managements",
    to: "/contentManagement",
  },
  {
    component: CNavItem,
    name: "Reports &\nAnalytics",
    to: "/report",
  },
];

export default _nav;
