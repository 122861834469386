import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { toast } from "react-toastify";
import { setBillingAllRecords, setBillingSummary, setBillingCoachRecords, setBillingStatus, setCptReports } from "../reducers/billingReducer";

export const billingApi = createApi({
    reducerPath: "billingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: base_url,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().user?.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Billing"],
    endpoints: (builder) => ({

        addCallLog: builder.mutation({
            query: ({ billingId, data }) => ({
                url: `/billing/add-call-log/${billingId}`,
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Call log added successfully!");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to add call log.");
                }
            },
            invalidatesTags: ["Billing"],
        }),

        getBillingSummaryByPatient: builder.query({
            query: ({ patient_id, from_date, to_date }) => {
                let queryString = `/billing/summary/${patient_id}`;

                if (from_date && to_date) {
                    queryString += `?from_date=${from_date}&to_date=${to_date}`;
                }

                return queryString;
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        dispatch(setBillingSummary(data));
                    }
                } catch (err) {
                    console.error("Error fetching billing summary:", err);
                }
            },
            providesTags: ["Billing"],
        }),
        getBillingReportStatus: builder.query({
            query: ({ clinic_id }) => `/billing/clinic-patient/${clinic_id}`,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        dispatch(setBillingStatus(data));
                    }
                } catch (err) {
                    console.error('Error fetching billing summary:', err);
                }
            },
            providesTags: ["BillingStatus"]
        }),
        getActiveBilling: builder.query({
            query: (patient_id) => `/billing/active/${patient_id}`,
            providesTags: ["Billing"],
        }),

        getAllBillingRecords: builder.query({
            query: ({ patient_id, fromDate, toDate }) => {
                let queryParams = new URLSearchParams();

                if (fromDate) queryParams.append("fromDate", fromDate);
                if (toDate) queryParams.append("toDate", toDate);

                return `/billing/all-billing/${patient_id}?${queryParams.toString()}`;
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        dispatch(setBillingAllRecords(data));
                    }
                } catch (err) {
                    console.error('Query Error:', err);
                }
            },
            providesTags: ["Billing"],
        }),
        getAllBillingRecordsFromCoach: builder.query({
            query: ({ coach_id, fromDate, toDate, searchQuery, sortOption, page, limit, searchQueryCallLog }) => {
                let queryParams = new URLSearchParams();

                if (fromDate) queryParams.append("fromDate", fromDate);
                if (toDate) queryParams.append("toDate", toDate);
                if (searchQuery) queryParams.append("searchQuery", searchQuery);
                if (sortOption) queryParams.append("sortOption", sortOption);
                if (page) queryParams.append("page", page);
                if (limit) queryParams.append("limit", limit);
                if (searchQueryCallLog) queryParams.append("searchQueryCallLog", searchQueryCallLog);

                return `/billing/all-billing-by-coach/${coach_id}?${queryParams.toString()}`;
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        dispatch(setBillingCoachRecords(data));
                    }
                } catch (err) {
                    console.error("Query Error:", err);
                }
            },
            providesTags: ["Billing"],
        }),
        // 🔹 Create a Billing Record
        createBilling: builder.mutation({
            query: (data) => ({
                url: "/billing/create-billing",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (err) {
                    console.error(err?.error?.data?.message || "Failed to create billing.");
                }
            },
            invalidatesTags: ["Billing"],
        }),

        // 🔹 Start Session
        startSession: builder.mutation({
            query: (data) => ({
                url: "/billing/start-session",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Session started successfully!");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to start session.");
                }
            },
            invalidatesTags: ["Billing"],
        }),

        // 🔹 Pause Session
        pauseSession: builder.mutation({
            query: (data) => ({
                url: "/billing/pause-session",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Session paused successfully!");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to pause session.");
                }
            },
            invalidatesTags: ["Billing"],
        }),

        // 🔹 Update Billing to "Ready to Bill"
        readyToBill: builder.mutation({
            query: (data) => ({
                url: "/billing/pending-status",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Billing marked as Pending!");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to update billing status.");
                }
            },
            invalidatesTags: ["Billing"],
        }),

        // 🔹 Update Billing to "Ready to Bill"
        updateSession: builder.mutation({
            query: ({ billingId, data }) => ({
                url: `/billing/update-session/${billingId}`,
                method: "PUT",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Session updated successfully!");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to update Session.");
                }
            },
            invalidatesTags: ["Billing"],
        }),
        updateStatus: builder.mutation({
            query: (payload) => ({
                url: `/billing/update-status`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Status updated successfully!");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to update Session.");
                }
            },
            invalidatesTags: ["BillingStatus"],
        }),

        // 🔹 Get Billing Session (Admin)
        getCptReports: builder.query({

            query: ({patient_id}) => `/billing/get-cpt-reports/${patient_id}`,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setCptReports(data));
                } catch (err) {
                    console.error(err);
                }
            },
            providesTags: ["Billing"],
        }),

        // 🔹 Admin: Cancel Billing
        cancelBilling: builder.mutation({
            query: (data) => ({
                url: "/billing/cancel-billing",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success("Billing session cancelled!");
                } catch (err) {
                    toast.error(err?.error?.data?.message || "Failed to cancel session.");
                }
            },
            invalidatesTags: ["Billing"],
        }),
    }),
});

export const {
    useGetBillingSummaryByPatientQuery,
    useGetActiveBillingQuery,
    useGetAllBillingRecordsQuery,
    useGetAllBillingRecordsFromCoachQuery,
    useCreateBillingMutation,
    useStartSessionMutation,
    usePauseSessionMutation,
    useReadyToBillMutation,
    useCancelBillingMutation,
    useAddCallLogMutation,
    useUpdateSessionMutation,
    useGetBillingReportStatusQuery,
    useUpdateStatusMutation,
    useGetCptReportsQuery
} = billingApi;
