import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const token = useSelector((state) => state?.user?.token);

  return token ? element : <Navigate to="/" replace />;
};

export default ProtectedRoute;
