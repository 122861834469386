import {
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileModal from "../Modal/ProfileModal";

const AppHeaderDropdown = ({setAvatarURL , avatarURL}) => {
  const navigate = useNavigate();
  const [profileModal, setProfileModal] = useState(false);
  const [criteria, setCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [showValidation, setShowValidation] = useState({
    password: false,
    confirmPassword: false,
  });
  const [formData, setFormData] = useState({
    title: '',
    first_name:'' ,
    last_name: '',
    password: '',
    profile_img:'',
  });
  const [checked, setChecked] = useState({
    alertChecked: false,
    notificationChecked: false,
  });
  const checkStrength = (password) => {
    const updatedCriteria = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
  
    setCriteria(updatedCriteria);
  };
  const closeModal = () => {
    setProfileModal(false);
    setCriteria({
      length: false,
      uppercase: false,
      lowercase: false,
      number: false,
      specialChar: false,
    });
setFormData({
  password:"",
  confirmPassword:"",
  avatarURL:avatarURL,
});
  };

  const logoutHandler = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <FontAwesomeIcon icon={faAngleDown} />
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem
            onClick={() => {
              setProfileModal(true);
            }}
            className="title-color mt-2"
          >
            Profile
          </CDropdownItem>
          <CDropdownDivider />
          <CDropdownItem className="title-color" onClick={logoutHandler}>
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>

      <ProfileModal
        checked={checked}
        checkStrength={checkStrength}
        onCloseModal={closeModal}
        profileModal={profileModal}
        setAvatarURL={setAvatarURL}
        avatarURL={avatarURL}
        criteria={criteria}
        setCriteria={setCriteria}
        showValidation={showValidation}
        formData={formData}
        setFormData={setFormData}
        setShowValidation={setShowValidation}
        setChecked={setChecked}
        setProfileModal={setProfileModal}
      />
    </>
  );
};

export default AppHeaderDropdown;
