import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { setCoachs, setUser } from "../reducers/coachReducer";

// Define a service using a base URL and expected endpoints
export const coachSlice = createApi({
  reducerPath: "Coach",
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["user"],
  endpoints: (builder) => ({
    getAllCoach: builder.query({
      query: () => `/coach/coachlisting`, 
      transformResponse: (response) => {
        return response.data;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data){
            dispatch(setCoachs(data));
          }
        } catch (error) {
          console.error("Error fetching coach data:", error);
        }
      },
    }),
    getAllUser: builder.query({
      query: ({searchText, filter, id, page, rowsPerPage}) => `/coachproviderlisting?searchtext=${searchText ? searchText : ""}&datefrom=${filter?.startDate ? filter?.startDate : ""}&dateto=${filter?.endDate ? filter?.endDate : ""}&status=${filter?.status ? filter?.status : ""}&userobjectId=${id ? id : ""}&page=${page ? page : 1}&limit=${rowsPerPage ? rowsPerPage : 10}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data){
            dispatch(setUser(data));
          }
        } catch (error) {
          console.error("Error fetching coach data:", error);
        }
      },
      providesTags: ["user"],
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetAllCoachQuery, useGetAllUserQuery } = coachSlice;
