import React from "react";
import { useNavigate } from "react-router-dom";
import Message from "../../assets/icons/messagesend.png";
import useGlobalStore from "../../global/ZustandStore";


const ResetSend = () => {

  const userType = useGlobalStore((state) => state.userType);
  const route_user = userType.toLowerCase();



    console.log("Types ", userType)
  const navigate = useNavigate();

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 col-md-6 intro-section">
            <div className="brand-wrapper"></div>
          </div>
          <div className="col-sm-6 col-md-6 form-section">
            <div className="login-wrapper ">
            

              <div className="text-center mt-3 pt-5 pb-2">
                <img src={Message} />
                
                <p className="para-reset ps-2 pt-3">
                  Please check your email for instructions on resetting your
                  password. If it's not visible in your inbox, please check your
                  spam folder.
                </p>
             
              </div>

            
              <div className="col-12">
                <div className="d-grid">
                  <button
                    onClick={() => {
                      // navigate(`/${route_user}/login  `);
                      navigate("/");
                    }}
                    className=" rv-reset-cancel"
                  >
                    Back to Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetSend;
