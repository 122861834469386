import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { toast } from "react-toastify";
import { setCoachAlertLog, setTemplate } from "../reducers/coachReducer";

export const coachPatientSlice = createApi({
    reducerPath: "coachPatients",
    baseQuery: fetchBaseQuery({
        baseUrl: base_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("access_token");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['patients'],
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: ({category, subCategory}) => `/getcategory/filters?category=${category ? category : ""}&hierarchy=${subCategory ? subCategory : ""}`,
            providesTags: ["patients"],
        }),
        assignTask: builder.mutation({
            query: ({data, id}) => ({
                url: `/updateroleuser/${id}`,
                method: 'PUT',
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        toast.success("Task Assigned Successfully!");
                    }
                } catch (err) {
                    toast.error(err?.error?.data?.message);
                }
            },
            invalidatesTags: ["patients"],
        }),
        deleteTask: builder.mutation({
            query: ({ id, payload }) => {
                return {
                    url: `/deletetask/${id}`,
                    method: 'DELETE',
                    body: payload
                };
            },
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        toast.success("Task Deleted Successfully!");
                    }
                } catch (err) {
                    toast.error(err?.error?.data?.message);
                }
            },
        }),
      addCoachReports:builder.mutation({
      query:(report)=>({
       url:'/coach/addcoachreport',
       method:'POST',
       body:report,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
            const { data } = await queryFulfilled;
            if (data) {
                toast.success("Report Added Successfully!");
            }
        } catch (err) {
            toast.error(err?.error?.data?.message);
        }
    },
      }),
        sendAlertMsg: builder.mutation({
            query: (data) => ({
                url: `/sendalertmsg`,
                method: 'POST',
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        toast.success("Alert Message Sended Successfully!");
                    }
                } catch (err) {
                    toast.error(err?.error?.data?.message);
                }
            },
        }),
        addTemplate: builder.mutation({
            query: (data) => ({
                url: `/create-template`,
                method: 'POST',
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        toast.success("Template Added Successfully!");
                    }
                } catch (err) {
                    toast.error(err?.error?.data?.message);
                }
            },
        }),
        getAllTemplate: builder.query({
            query: ({page, rowsPerPage}) => `/get-template?page=${page}&limit=${rowsPerPage}`, 
              async onQueryStarted(arg, { dispatch, queryFulfilled }) {
              try {
                const { data } = await queryFulfilled;
                if(data){
                  dispatch(setTemplate(data));
                }
              } catch (error) {
                console.error("Error fetching coach data:", error);
              }
            },
            
        }),
        deleteTemplate: builder.mutation({
            query: (id) => ({
                url: `/deletetemplate/${id}`,
                method: 'Delete'
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        toast.success("Template Deleted Successfully!");
                    }
                } catch (err) {
                    toast.error(err?.error?.data?.message);
                }
            },
        }),
        getCoachAlerLog: builder.query({
            query: ({coachId, providerId, patientId}) => {
                const params = new URLSearchParams();
            
                if (coachId) params.append('coach_id', coachId);
                if (providerId) params.append('provider_id', providerId);
                if (patientId) params.append('patient_id', patientId);
            
                return `/get/getalertlisting?${params.toString()}`;
              },
              async onQueryStarted(arg, { dispatch, queryFulfilled }) {
              try {
                const { data } = await queryFulfilled;
                if(data){
                  dispatch(setCoachAlertLog(data));
                }
              } catch (error) {
                console.error("Error fetching coach data:", error);
              }
            },
        }),
        sendEmail: builder.mutation({
            query: (data) => ({
                url: `/coach/sendemail`,
                method: 'POST',
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        toast.success("Email Sended Successfully!");
                    }
                } catch (err) {
                    toast.error(err?.error?.data?.message);
                }
            },
        }),
    }),
});

// Export hooks for usage in functional components
export const { useSendEmailMutation, useAssignTaskMutation, useGetCategoriesQuery, useSendAlertMsgMutation,useAddCoachReportsMutation,useDeleteTaskMutation, useGetAllTemplateQuery, useAddTemplateMutation, useDeleteTemplateMutation, useGetCoachAlertMessageQuery, useGetCoachAlerLogQuery } = coachPatientSlice;
