import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { setActivity } from "../reducers/patientUseFullResourcesSlice";
import { toast } from "react-toastify";

// Define a service using a base URL and expected endpoints
export const activitySlice = createApi({
  reducerPath: "activity",
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Activity'],
  endpoints: (builder) => ({
    getAllActivity: builder.query({
      query: ({page, rowsPerPage}) => `/admin/activitylist?page=${page}&limit=${rowsPerPage}`, 
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data){
            dispatch(setActivity(data));
          }
        } catch (error) {
          console.error("Error fetching coach data:", error);
        }
      },
      providesTags: ["Activity"],
    }),
    deleteActivity: builder.mutation({
      query: (id) => ({
          url: `/admin/deleteactivity/${id}`,
          method: 'PATCH',
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            toast.success("Activity deleted successfully!");
          }
        } catch (err) {
          toast.error(`Error: ${err?.message || "Failed to delete activity."}`);
        }
      },
      invalidatesTags: ["Activity"],
    }),
    addActivity: builder.mutation({
      query: (activity) => ({
        url: `/admin/addactivity`,
        method: 'POST',
        body: activity,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            toast.success("Activity added successfully!");
          }
        } catch (err) {
          toast.error(`Error: ${err?.message || "Failed to add activity."}`);
        }
      },
      invalidatesTags: ["Activity"],
    }),
    updateActivity: builder.mutation({
      query: ({data, id}) => ({
        url: `/admin/editactivity/${id}`,
        method: 'PUT',
        body: data,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data) {
              toast.success("Activity updated successfully!");
            }
          } catch (err) {
            toast.error(`Error: ${err?.message || "Failed to update activity."}`);
          }
        },
        invalidatesTags: ["Activity"],
    })
  }),
});

// Export hooks for usage in functional components
export const { useGetAllActivityQuery, useDeleteActivityMutation, useAddActivityMutation, useUpdateActivityMutation } = activitySlice;
