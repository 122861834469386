import React, { useEffect } from "react";
import {
  AppContent,
  AppSidebar,
//   AppFooter,
  AppHeader,
} from "./Index";
import useGlobalStore from "../../global";

const DefaultLayout = () => {
  const userType = useGlobalStore((state) => state.userType);
  const storageUser  =  localStorage.getItem('userType')


  return (
    <div>
      {storageUser === "Admin"   ?  <AppSidebar /> :""}
      <div className="wrapper d-flex flex-column min-vh-100  bg-light">
        <AppHeader  userType = {storageUser}/>
        <div className="flex-grow-1 px-3" >
          <AppContent />  
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  );
};

export default DefaultLayout;
