import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultLayout from "./Components/Layout/Defaultlayout";
import Reset from "./Components/Login/Reset";
import ResetMessage from "./Components/Login/ResetSend";
import ConfirmPassword from "./Components/Login/ConfirmPassword";
import Changed from "./Components/Login/Changed";
const Login = React.lazy(() => import("./Components/Login/Login"));

const LoadingSpinner = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  // const userRoutes = [
  //   { path: "/provider/login", userType: "Provider" },
  //   { path: "/admin/login", userType: "Admin" },
  //   { path: "/coach/login", userType: "Coach" },
  // ];

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {/* {userRoutes.map(({ path, userType }) => (
            <Route
              key={path}
              path={path}
              element={<Login userType={userType} />}
            />
          ))} */}
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/reset" element={<ResetMessage />} />
          <Route path="/confirm-password" element={<ConfirmPassword />} />
          <Route path="/changed" element={<Changed />} />
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
