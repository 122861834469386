import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from '../../global/global';
import { setTrainingResources } from '../reducers/patientUseFullResourcesSlice';
import { toast } from 'react-toastify';

export const trainingResourcesSlice = createApi({
  reducerPath: 'trainingResources',
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user?.token;
      
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  tagTypes: ['trainingResources'],
  endpoints: (builder) => ({
    getAllTrainingResources: builder.query({
      query: ({page, rowsPerPage}) => `/admin/trainingresourcelist?page=${page}&limit=${rowsPerPage}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data){
            dispatch(setTrainingResources(data));
          }
        } catch (err) {
          console.error('Query Error:', err);
        }
      },
      providesTags: ['trainingResources'],
    }),
    addTrainingResources: builder.mutation({
        query: (payload) => ({
            url: '/admin/addtrainingresource',
            method: 'POST',
            body: payload,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("Training resource added successfully!");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        },       
        invalidatesTags: ["trainingResources"],
    }),
    updateTrainingResources: builder.mutation({
        query: ({payload, id}) => ({
            url: `/admin/edittrainingresource/${id}`,
            method: 'PUT',
            body: payload,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
          
          try {
            const { data } = await queryFulfilled;
            if(data){
              toast.success("Training resource updated successfully!");
              }
            } catch (err) {
              toast.error(err?.error?.data?.message);
            }
          },      
        invalidatesTags: ["trainingResources"],
    }),
    deleteTrainingResources: builder.mutation({
        query: (id) => ({
            url: `/admin/deletetrainingresource/${id}`,
            method: 'PATCH',
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
          
          try {
            const { data } = await queryFulfilled;
            if(data){
              toast.success("Training resource deleted successfully!");
              }
            } catch (err) {
              toast.error(err?.error?.data?.message);
            }
          },   
        invalidatesTags: ["trainingResources"],
    }),
  }),
});

export const { useGetAllTrainingResourcesQuery, useAddTrainingResourcesMutation, useDeleteTrainingResourcesMutation, useUpdateTrainingResourcesMutation } = trainingResourcesSlice;
