import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from '../../global/global';
import { setBillingPolicies } from '../reducers/patientUseFullResourcesSlice';
import { toast } from 'react-toastify';

export const billingPoliciesSlice = createApi({
  reducerPath: 'billingPolicies',
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user?.token;
      
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  tagTypes: ['billingPolicies'],
  endpoints: (builder) => ({
    getAllBillingPolicies: builder.query({
      query: ({page, rowsPerPage}) => `/admin/billingpolicylist?page=${page}&limit=${rowsPerPage}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data){
            dispatch(setBillingPolicies(data));
          }
        } catch (err) {
          console.error('Query Error:', err);
        }
      },
      providesTags: ['billingPolicies'],
    }),
    postBillingPolicies: builder.mutation({
        query: (data) => ({
            url: '/admin/addbillingpolicy',
            method: 'POST',
            body: data,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
          
          try {
            const { data } = await queryFulfilled;
            if(data){
              toast.success("Billing added successfully!");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        },
        invalidatesTags: ['billingPolicies']
    }),
    updateBillingPolicies: builder.mutation({
        query: ({payload, id}) => ({
            url: `/admin/editbillingpolicy/${id}`,
            method: 'PUT',
            body: payload,
        }),
        async onQueryStarted({flag}, { queryFulfilled }) {
          
          try {
            const { data } = await queryFulfilled;
            if (flag === "Edit") {            
              toast.success("Billing Updated Successfully!"); 
            } else if (flag === "status") {
              if (data.data.status === "active") {           
                toast.success("Billing Activated Successfully!"); 
              } else { 
                toast.success("Billing De-Activated Successfully!"); 
              }
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        },
        invalidatesTags: ['billingPolicies']
    }),
    deleteBillingPolicy: builder.mutation({
        query: (id) => ({
            url: `/admin/deletebillingpolicy/${id}`,
            method: 'PATCH',
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
          
          try {
            const { data } = await queryFulfilled;
            if(data){
              toast.success("Billing deleted successfully!");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        },
        invalidatesTags: ['billingPolicies']
    })
  }),
});

export const { useGetAllBillingPoliciesQuery, usePostBillingPoliciesMutation, useUpdateBillingPoliciesMutation, useDeleteBillingPolicyMutation } = billingPoliciesSlice;
