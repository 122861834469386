import React from "react";

const Clinics = React.lazy(() => import("./views/pages/admin/clinic/Clinic"));
const ClinicDetail = React.lazy(() => import("./views/pages/admin/clinic/ClinicDetail"));
const Patients = React.lazy(() => import("./views/pages/admin/patients/PatientList"));
const Users = React.lazy(() => import("./views/pages/admin/users/UserList"));
const UserDetail = React.lazy(() => import("./views/pages/admin/users/UserDetail"));
const ContentManagement = React.lazy(() => import("./views/pages/admin/content/ContentManagement"));
const ReportNanalytics = React.lazy(() =>  import("./views/pages/admin/reportAnalytics/ReportNanalytics"));
const MyPatients = React.lazy(() =>  import("./views/pages/provider/MyPatients"));
const MyPatientsDetails = React.lazy(() =>  import("./views/pages/provider/MyPatientDetails"));
const Messages = React.lazy(() =>  import("./views/pages/provider/Messages"));



// Admin Routes

const adminRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/clinics", name: "List of Practices", element: Clinics },
  { path: "/clinics/clinicDetail",    name: "Clinic Detail",    element: ClinicDetail  },
  { path: "/patients", name: "Patients", element: Patients },
  { path: "/users", name: "Client", element: Users },
  { path: "/users/userDetail", name: "Client Detail", element: UserDetail },
  { path: "/contentManagement",    name: "ContentManagement",    element: ContentManagement  },
  { path: "/report", name: "Report & analytics", element: ReportNanalytics },
];

// Provider Routes

const providerRoutes =[
  { path: "/mypatients", name: "MyPatients", element: MyPatients },
  { path: "/mypatients/details", name: "Patient Detail", element: MyPatientsDetails },
  { path: "/messages", name: "Message Coach", element: Messages },

]

export default {adminRoutes, providerRoutes};
// export default routes;
